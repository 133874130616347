import React, { Fragment } from 'react'
import styled from 'styled-components'
import SEO from '../components/SEO'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.greyLight};
  color: ${props => props.theme.colors.red};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: calc(100vh - 174px);
  h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
`

const NotFoundPage = () => (
  <Fragment>
    <SEO title={`404`} />
    <Wrapper>
      <h1>404</h1>
      <p>Page Not Found</p>
    </Wrapper>
  </Fragment>
)

export default NotFoundPage
